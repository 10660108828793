.App {
  text-align: center;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
.jstify{
  text-align: justify;
}
.hundred{
  width: 100px;
  height: 100px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media only screen and (max-width:499px){
  .hide{
    display: none;
  }
  .reverse{
    flex-direction: row-reverse;
  }
}
/* App.css or specific component's CSS file */
body {
  cursor: none; /* Hide cursor for the entire document */
}
.hovereffect2:hover{
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.10);
  transform: scale(1.00);
  cursor: pointer;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.hovereffect:hover{
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
  transform: scale(1.03);
  cursor: pointer;
}
.remove{
  display: none !important;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-in {
  animation: slideInFromLeft 0.5s ease-out;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* .blur{
  width: 100%;
 z-index: 200;
  background: rgba(255, 255, 255, 0.692);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(30px);
  
} .blur::after{
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.582);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(30px);
} */

.blurred-section {
  filter: blur(30px); /* Adjust the blur radius as needed */
  

}

@media only screen and (max-width:1000px){
  .hide{
    display: hidden;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width:490px) {
  .font-resize{
    font-size: 36px;
  }
}
@media screen and (max-width:768px) {
  .font-resize{
    font-size: 56px;
  }
  .marT{
    margin-top: 2rem;
  }
}



#ball {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border: 2px solid #000; /* Initial color is black */
  border-radius: 50%;
  pointer-events: none;
  opacity: 1;
  z-index: 1000001; /* Ensure the ball is above dot */
}

#dot {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #000; /* Initial color is black */
  border-radius: 50%;
  pointer-events: none;
  opacity: 1;
  z-index: 1000002; /* Ensure the dot is on top of everything */
}

.whitedot {
  background-color: #fff !important;
}

.whiteball {
  border: 2px solid #fff !important;
}

@media only screen and (max-width: 769px) {
  #magic-cursor {
    display: none;
  }
}






/* Add this to your CSS file */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  float: right;
  cursor: pointer;
}




/* Loader.css */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.flip{
  transform-style: preserve-3d;
  animation: flip 3s infinite;
}
@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.faq-item {
  margin-bottom: 16px; 
  transition: max-height 2s ease-out; 
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-out; 
}

.faq-answer.open {
  max-height: 500px;
  transition: max-height 2s ease-out; 
}

.answer-content {
  padding: 0 24px; 
}
